import React from "react";
import Heading from "../heading";

const AddressBlock = () => (
  <>
    <Heading variant="h3" className="text-3xl md:text-4-1/2xl leading-none mb-4 md:mb-10">
      Where to find us
    </Heading>
    <ul className="leading-relaxed text-lg">
      <li>6B Guiseley Way</li>
      <li>Durham Lane Industrial Estate</li>
      <li>Egglescliffe</li>
      <li>TS16 0RF</li>
      <br />
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
          href="https://www.google.com/maps/place/Distinct+Performance/@54.5299964,-1.360682,17z/data=!3m1!4b1!4m5!3m4!1s0x487e9330e682c171:0x5e90379a4ef34859!8m2!3d54.5299933!4d-1.3584933"
        >
          Find us on Google Maps
        </a>
      </li>
      <br />
      <li>
        T: <a href="tel:07944203931">07944 203931</a>
      </li>
      <li>
        E: <a href="mailto:distinctperform@gmail.com">distinctperform@gmail.com</a>
      </li>
    </ul>
  </>
);

export default AddressBlock;
