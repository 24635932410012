import React from "react";
import { Router } from "@reach/router";

import Layout from "../../layouts";
import PostView from "./PostView";
import PageView from "./PageView";

const NotFound = () => <p>Sorry, nothing here</p>;

const Preview = () => (
  <Layout>
    <Router>
      <NotFound default />
      <PostView exact path="/preview/post/:id" />
      <PageView exact path="/preview/page/:id" />
    </Router>
  </Layout>
);

export default Preview;
