import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";

const Author = ({ author }) => (
  <div className="flex items-center">
    <div className="bg-distinct-grey-silver h-16 mr-6 rounded-full w-16">
      {author.image && (
        <Image fluid={author.image.url} className="rounded-full w-full h-full" />
      )}
    </div>
    <div className="font-mono text-lighter text-xl">
      <p>{author.name}</p>
    </div>
  </div>
);

Author.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
  }).isRequired,
};

export default Author;
