/* eslint-disable react/no-array-index-key */

import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";

const Breadcrumb = ({ items }) => (
  <ul className="flex font-mono list-disc mb-4 md:mb-8 text-distinct-grey-emperor">
    {items.map((item, i) => (
      <li className="first:list-none first:ml-0 first:pl-0 ml-10 pl-4" key={`test-${i}`}>
        {item.route ? (
          <Link
            to={item.route}
            className="hover:opacity-80 transition ease-in-out duration-200"
          >
            {item.name}
          </Link>
        ) : (
          <span className="opacity-75">{item.name}</span>
        )}
      </li>
    ))}
  </ul>
);

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Breadcrumb;
