import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SanityClient from "@sanity/client";
// import imageUrlBuilder from "@sanity/image-url";

import Heading from "../../components/heading";
import PortableText from "../../components/portable-text";
import HeroGeneral from "../../components/heroes/general";
import AddressBlock from "../../components/address-details";

/**
 * @TODO
 * This needs to be moved to a config file
 * of some kind, maybe use .env file too...
 */
const config = {
  projectId: "3hmxm3df",
  dataset: "production",
};

const PreviewClient = SanityClient({
  projectId: config.projectId,
  dataset: config.dataset,
  useCdn: false,
  withCredentials: true,
});

// const builder = imageUrlBuilder(PreviewClient);

/**
 * Generate image url for Sanity
 */
// const urlFor = source => builder.image(source);

const View = ({ id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = "*[_type == 'page' && _id == $id]";
    const params = { id };

    PreviewClient.fetch(query, params).then(page => {
      if (page.length > 0) {
        setData(page[0]);
      }

      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return (
      <div className="max-w-screen-desktop mb-8 mx-auto px-6 pt-10">
        <Heading className="text-6xl mb-">Post not found. Please try again.</Heading>
      </div>
    );
  }

  return data ? (
    <>
      <HeroGeneral title={data.title} />

      <div className="font-standard flex flex-col md:flex-row max-w-screen-desktop mb-8 md:mb-16 mx-auto px-6">
        <div className="md:w-3/4 mb-8 md:mb-0 md:mr-20 text-xl">
          <PortableText blocks={data.body} />
        </div>
        <div className="font-standard md:w-1/4 mb-10 md:mb-0">
          <AddressBlock />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="max-w-screen-desktop mb-8 mx-auto px-6 pt-10">
        <Heading className="text-6xl mb-">Post not found. Please try again.</Heading>
      </div>
    </>
  );
};

View.propTypes = {
  id: PropTypes.string.isRequired,
};

export default View;
