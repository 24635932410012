import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

import Author from "../../components/author-block";
import Heading from "../../components/heading";
import Breadcrumb from "../../components/breadcrumb";
import PortableText from "../../components/portable-text";

/**
 * @TODO
 * This needs to be moved to a config file
 * of some kind, maybe use .env file too...
 */
const config = {
  projectId: "3hmxm3df",
  dataset: "production",
};

const PreviewClient = SanityClient({
  projectId: config.projectId,
  dataset: config.dataset,
  useCdn: false,
  withCredentials: true,
});

const builder = imageUrlBuilder(PreviewClient);

/**
 * Generate image url for Sanity
 */
const urlFor = source => builder.image(source);

const View = ({ id }) => {
  const [data, setData] = useState(null);
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = "*[_type == 'post' && _id == $id]";
    const params = { id };

    PreviewClient.fetch(query, params).then(post => {
      if (post.length > 0) {
        setData(post[0]);

        // Find the author
        const authorQuery = "*[_type == 'author' && _id == $authorId]";
        const authorParams = { authorId: post[0].author._ref };

        PreviewClient.fetch(authorQuery, authorParams).then(a => {
          setAuthor(a[0]);
        });
      }

      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return (
      <div className="max-w-screen-desktop mb-8 mx-auto px-6 pt-10">
        <Heading className="text-6xl mb-">Post not found. Please try again.</Heading>
      </div>
    );
  }

  return data ? (
    <>
      <header className="flex flex-col md:flex-row max-w-screen-desktop mb-8 md:mb-16 mx-auto px-6 pt-12">
        <div className="w-1/2">
          <Breadcrumb
            items={[
              {
                route: "/blog/",
                name: "Blog",
              },
              {
                name: data.title,
              },
            ]}
          />
          <Heading className="text-6xl mb-8">{data.title}</Heading>

          <div className="text-2xl mb-8">
            <PortableText blocks={data.lede} />
          </div>

          {author && <Author author={{ name: author.name }} />}
        </div>

        <div className="w-1/2 pl-8 pt-3">
          <img
            className="w-full"
            style={{ width: "100%", height: 430, objectFit: "cover" }}
            src={urlFor(data.featureImage).url()}
            alt={data.title}
          />
        </div>
      </header>

      <div className="flex flex-col md:flex-row max-w-screen-desktop mb-8 md:mb-16 mx-auto px-6">
        <div className="font-standard  md:w-3/4 mb-8 md:mb-0 md:mr-20">
          <PortableText blocks={data.body} />
        </div>

        <aside className="font-standard md:w-1/4 mb-10 md:mb-0">
          <Heading
            variant="h3"
            className="text-3xl md:text-4-1/2xl leading-none mb-4 md:mb-10"
          >
            Quick Links
          </Heading>
          <ul className="leading-relaxed text-lg">
            <li>Heading Here</li>
          </ul>
        </aside>
      </div>
    </>
  ) : (
    <>
      <div className="max-w-screen-desktop mb-8 mx-auto px-6 pt-10">
        <Heading className="text-6xl mb-">Post not found. Please try again.</Heading>
      </div>
    </>
  );
};

View.propTypes = {
  id: PropTypes.string.isRequired,
};

export default View;
